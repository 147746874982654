@import "../../variables";

.area-usuario {
    //background: #000 url(../../assets/area-usuario/bg-area-usuario.jpg) no-repeat top left / cover;
    background: $azulClaro;
    min-height: 100vh;

    a.inativo {
        opacity: 0.3;
        pointer-events: none;
    }

    .itens {
        //max-width: 910px;
        &.tipo-perfil-Presencial {
            a[href="/transmissao-ao-vivo"] {
                display: none;
            }
        }
    }

    .MuiButton-root {
        text-align: center;
        flex-wrap: wrap;
        position: relative;
        margin: 50px;
        align-items: flex-end;
        width: 110px;
        color: $azulEscuro;

        &:hover {
            &::before {
                transform: skewX(-5deg) scale(1.3) translateX(15px);
            }

            &::after {
                background: rgba($color: #fff, $alpha: 0.8);
            }
        }

        &::before {
            content: '';
            width: 100%;
            height: 100%;
            transform: skewX(-5deg) scale(1.3) translateX(0);
            background: $azulEscuro;
            position: absolute;
            top: 30px;
            left: 5px;
            //opacity: 0.7;
            @include exporOut();
        }

        &::after {
            content: '';
            width: 100%;
            height: 100%;
            transform: skewX(-5deg) scale(1.3);
            background: #fff;
            position: absolute;
            top: 0;
            left: 0;
            @include easeOut();
        }

        .txt {
            position: relative;
            z-index: 1;

            img {
                display: inline-block;
                width: 70px;
            }

            h3 {
                display: block;
                width: 100%;
                color: $roxo;
                font-size: 12px;
                margin-top: 20px;
                min-height: 34px;
                display: flex;
                justify-content: center;
                align-items: center;
                color: $azulEscuro;
            }

            span {
                display: block;
                font-family: 'BMW Motorrad Outline';
                font-size: 16px;
                color: #fff;
                margin-top: 10px;
                margin-bottom: -57px;
            }
        }

        .MuiTouchRipple-root {
            z-index: 2;
        }
    }
}