@import "../../variables";

.page.minha-conta {
    background: $azulClaro;
    min-height: 100vh;
    color: $azulEscuro;

    h1 {
        margin: 0 auto;
        text-transform: uppercase;
        font-size: 48px;
        margin-top: 30px;
        margin-bottom: 20px;
        width: 70%;
    }

    .esq {
        width: 35%;

        .dados-pessoais {
            .MuiTextField-root {
                width: 100%;
                margin-bottom: 10px;

                .MuiInputLabel-root {
                    font-size: 19px;
                    line-height: 22px;
                    color: #000;
                    text-transform: uppercase;
                    transform: translate(14px, 5px) scale(1);
                    z-index: 2;

                    &.MuiFormLabel-filled,
                    &.Mui-focused {
                        padding-right: 7px;
                        background: $rosa;
                        color: #fff;
                        transform: translate(14px, -5px) scale(0.5);
                    }
                }

                .MuiInputBase-input {
                    font-size: 14px;
                    line-height: 16px;
                    padding: 5px 15px;
                    color: #000;
                }

                .MuiInputBase-root {
                    input {
                        background: none !important;

                        &:-internal-autofill-selected {
                            background-color: none !important;
                        }
                    }
                }

                .MuiOutlinedInput-notchedOutline,
                .Mui-focused .MuiOutlinedInput-notchedOutline {
                    border: solid 3px $rosa;
                }
            }

            .box-pass {
                position: relative;

                .bt-toggle-pass {
                    position: absolute;
                    right: 10px;
                    top: 7px;
                    width: 15px;
                    height: 15px;
                    min-width: auto;
                    padding: 0;
                    margin: 0;
                    color: #000;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    z-index: 9;
                }
            }
        }

        .dado-contato {
            padding-top: 20px;

            h2 {
                font-size: 16px;
                margin-bottom: -10px;
            }

            p {
                font-size: 12px;
                margin-bottom: 25px;
            }
        }
    }

    .dir {
        width: 35%;

        .ico-usu {
            position: relative;

            .box-foto {
                svg {
                    width: 155px;
                    height: 155px;
                }

                i {
                    display: block;
                    width: 155px;
                    height: 155px;
                    background-size: cover;
                    background-repeat: no-repeat;
                    background-position: center center;
                    border-radius: 100%;
                    margin-bottom: 12px;
                }
            }

            .box-bt {
                position: absolute;
                bottom: 20px;
                left: 10px;

                .bt-add,
                .bt-upload {
                    color: #fff;
                    background: $rosa;
                    padding: 5px;
                    min-width: auto;
                    border-radius: 100%;

                    &:hover {
                        background: $azul;
                    }

                    svg {
                        width: 40px;
                        height: 40px;
                    }
                }
            }

            h4 {
                font-size: 12px;
                text-transform: uppercase;
                text-align: center;
                margin-top: -5px;
            }
        }

        .bt {
            font-size: 12px;
            margin-top: 20px;
            background: $rosa;
            color: #fff;
        }
    }

    .MuiTextField-root {
        width: 100%;
        margin-bottom: 10px;

        .MuiInputLabel-root {
            font-size: 19px;
            line-height: 22px;
            color: $azulEscuro;
            text-transform: uppercase;
            transform: translate(14px, 5px) scale(1);
            z-index: 2;

            &.MuiFormLabel-filled,
            &.Mui-focused {
                padding: 0 7px;
                background: $rosa;
                color: #fff;
                transform: translate(14px, -5px) scale(0.5);
            }
        }

        .MuiInputBase-input {
            font-size: 14px;
            line-height: 16px;
            padding: 5px 15px;
            color: $azul;
        }

        .MuiOutlinedInput-notchedOutline {
            border: solid 3px $rosa;
        }
    }

    .box-bt-salvar {
        margin: 0 auto;
        width: 70%;

        .bt {
            margin-top: 15px;
            background: $rosa;
            color: #fff;
        }
    }
}

@media (max-width: 1200px) {
    .minha-conta .esq h1 {
        font-size: 42px;
    }
}

@media (max-width: 992px) {
    .minha-conta .esq h1 {
        font-size: 32px;
    }
}

@media (max-width: 768px) {

    .page.minha-conta .esq,
    .page.minha-conta .dir {
        width: 100%;
    }

    .page.minha-conta .dir {
        padding-bottom: 25px;
    }

    .page.minha-conta h1 {
        width: 100%;
        font-size: 35px;
        text-align: center;
    }

    .page.minha-conta .container-fluid>.flex {
        flex-flow: column-reverse;
    }

    .page.minha-conta .box-bt-salvar {
        width: 100%;
    }
}