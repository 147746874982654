@import "../../variables";

header {
    color: $azulEscuro;
    padding: 10px 20px;
    font-size: 20px;
    text-align: center;
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;

    img.kv {
        width: calc(100% - 95px);
        max-width: 550px;
        position: relative;
        left: -40px;
        margin-top: -10px;
    }

    .flex {
        .MuiButton-root a {
            background: none;
            font-size: 26px;
            border-bottom: solid 5px;
            padding: 0;
            border-radius: 0;
            line-height: 100%;
            padding-bottom: 3px;
            color: $azulEscuro;

            &:hover {
                color: $rosa;
                background: none;
            }
        }
    }
}

body {

    &.minha-conta-body,
    &.area-usuario-body {
        .MuiButton-root.bt-sair {
            display: flex;
        }
    }

    .MuiButton-root.bt-sair {
        padding: 0;
        width: auto;
        height: auto;
        min-width: auto;
        min-height: auto;
        margin-left: 5px;
        color: #fff;
        background: $rosa;
        border-radius: 100%;
        position: fixed;
        bottom: 70px;
        right: 20px;
        display: none;
        z-index: 9;

        &:hover {
            background: $azul;
            color: #fff;
        }

        svg {
            width: 25px;
            height: 25px;
            padding: 5px;
        }
    }
}

@media (max-width: 768px) {
    header {
        padding: 10px 0;

        img.logo {
            width: 180px;
        }
    }
}