@import "../../variables";

body.login {
    footer {
        display: none;
    }
}

footer {
    color: $azulEscuro;
    position: absolute;
    padding: 0 20px;
    bottom: 0;
    left: 0;
    width: 100%;
    padding-bottom: 30px;

    h3 {
        font-size: 18px;
        font-weight: 700;
        text-transform: uppercase;
        margin-bottom: 5px;
        padding-bottom: 5px;
        border-bottom: solid 4px;
        display: inline-block;
    }

    .flex {
        width: 100%;
    }

    img {
        width: 150px;
        margin-bottom: 15px;
    }

    h4 {
        text-transform: uppercase;
        font-weight: 400;
        font-size: 11px;

        .MuiButton-root {
            color: $azulEscuro;
            text-decoration: underline;
            font-size: 14px;
            padding: 0;
            position: relative;
            top: -2px;

            &:focus {
                outline: none;
            }

            &:hover {
                color: $rosa;
            }
        }
    }
}

@media (max-width: 992px) {
    footer h4 {
        font-size: 12px;
    }
}

@media (max-width: 768px) {
    footer h4 {
        margin-bottom: 10px;

        &:last-child {
            margin: 0;
        }
    }
}