@import "../../variables";

body.login {
    header {
        display: none;
    }
}

.full-area {
    position: relative;
    left: calc(50% - 50vw);
    width: 100vw;
}

section.gestao-saude {
    background: $azulClaro;
    position: relative;
    z-index: 9;
    padding: 30px 0;

    img.logo {
        position: relative;
        left: 0;
        width: 100%;
        max-width: 250px;
    }

    .MuiButton-root {
        text-decoration: underline;
        font-size: 20px;
    }

    img.kv {
        width: calc(100% - 90px);
        max-width: 1137px;
    }
}

section.evento-comeca {
    background: $azulClaro;
    position: relative;
    z-index: 9;

    .flex {
        .esq {
            h3 {
                font-size: 35px;
                font-weight: 900;
                font-family: 'Open Sans', sans-serif;
                margin-left: 0;
                margin-right: 30px;
                position: relative;
                top: 0;
            }
        }

        .dir {
            .bg {
                position: relative;
                width: 100%;
                height: 115px;

                &::before {
                    content: '';
                    background: $azulEscuro;
                    width: 100vw;
                    height: 100%;
                    position: absolute;
                    top: 0;
                    left: 0;
                    transform: skewX(-25deg);
                }

                &::after {
                    content: '';
                    background: $rosa;
                    width: 100vw;
                    height: 100%;
                    position: absolute;
                    top: 30px;
                    left: 0;
                    transform: skewX(-25deg);
                }
            }

            .txt {
                position: relative;
                top: -110px;
                left: 35px;

                .box-count {
                    span {
                        color: $azulClaro;
                        font-size: 35px;
                        position: relative;
                        top: 55px;
                    }
                }
            }

            h4 {
                font-size: 20px;
                color: $azulClaro;
            }
        }
    }

    .linha-azul {
        min-height: 200px;
        padding-top: 30px;
        position: relative;
        top: 110px;
        margin-top: -90px;
        width: 100vw;
        left: 0;
        overflow: hidden;

        .container {
            position: relative;
        }

        i.linha-azul-v2 {
            background: url(../../assets/lp/linha-azul.png) no-repeat center left;
            height: 165px;
            width: 2500px;
            left: -165px;
            top: 0;
            position: absolute;
        }

        h4 {
            color: $rosa;
            font-size: 20px;
            position: relative;
            text-transform: uppercase;
            //left: 0;
            padding-left: 0;
            top: -30px;
        }

        p {
            font-size: 18px;
            position: relative;
            //left: 0;
            padding-left: 0;
            top: -20px;
        }
    }
}

section.sobre-o-evento {
    background: #fff;
    position: relative;
    z-index: 8;
    padding-top: 60px;
    padding-bottom: 10px;

    h2 {
        position: relative;
        display: inline-block;
        margin-left: 0;
        margin-bottom: 30px;

        i {
            position: absolute;
            display: inline-block;
            width: 100%;
            height: 100%;

            &::before {
                content: '';
                background: $azulEscuro;
                width: 100%;
                height: 100%;
                position: absolute;
                top: 0;
                left: 0;
                transform: skewX(-25deg);
            }

            &::after {
                content: '';
                background: $rosa;
                width: 100%;
                height: 100%;
                position: absolute;
                top: 10px;
                left: 5px;
                transform: skewX(-25deg);
            }
        }

        span {
            display: inline-block;
            position: relative;
            color: #fff;
            font-size: 24px;
            padding: 10px;
            line-height: 120%;
            margin-left: 20px;
            margin-top: 20px;
            margin-right: 25px;
        }
    }

    .container {
        >p {
            font-size: 18px;
            margin-left: 0;
            line-height: 100%;
        }

        .cols {
            padding-top: 20px;

            .col {
                width: calc(33.33% - 15px);

                h2 {
                    display: inline-block;
                    margin-left: 0;
                    text-align: center;
                    position: relative;
                    width: 100%;

                    i {
                        &::before {
                            background: $rosa;
                        }

                        &::after {
                            background: $azulEscuro;
                        }
                    }

                    span {
                        font-size: 22px;
                        width: 100%;
                        margin-left: 0;
                    }
                }

                p {
                    font-size: 18px;
                    line-height: 120%;
                }

                img {
                    width: 100%;
                    max-width: 240px;
                }
            }
        }
    }

    .flex {
        padding-left: 0;

        img {
            margin-right: 20px;
        }

        p {
            font-size: 24px;
            line-height: 100%;
        }
    }
}

section.cadastro-presencial {
    background: $azulClaro;
    position: relative;
    z-index: 8;
    padding-top: 40px;
    padding-bottom: 75px;

    i.linha-rosa {
        background: url(../../assets/lp/linha-rosa.png) no-repeat center right;
        width: 100%;
        height: 165px;
        display: block;
        margin-top: -148px;
    }

    .cols {

        .esq,
        .dir {
            width: calc(50% - 20px);
        }
    }

    h2 {
        color: $rosa;
        font-size: 26px;
        font-weight: 900;
        line-height: 120%;
        margin-top: 30px;
        margin-left: 0;
        margin-bottom: 15px;

        span {
            color: $azulEscuro;
        }
    }

    .frase {
        margin-top: 20px;
    }

    p {
        font-size: 18px;
        margin-left: 0;
        line-height: 120%;
        min-height: 130px;
    }

    .box-login {
        max-width: 400px;
        transform: skewX(-4deg);
        position: relative;
        margin-left: 0;

        >div {
            padding: 0 5px;

            >.MuiButton-root {
                margin-top: 10px;
                color: $azulEscuro;
            }
        }
    }
}

section.cadastro-online {
    position: relative;
    z-index: 8;
    padding-top: 40px;
    padding-bottom: 10px;
    background: #fff;

    i.linha-azul {
        background: url(../../assets/lp/linha-azul.png) no-repeat center left;
        width: 100%;
        height: 165px;
        display: block;
        margin-top: -97px;
        margin-bottom: -115px;
    }

    h2 {
        color: $rosa;
        font-size: 50px;
        font-weight: 900;
        line-height: 120%;
        margin-top: 30px;
        margin-left: 0;
        margin-bottom: 30px;
    }

    .frase {
        margin-top: 20px;
    }

    p {
        font-size: 24px;
        margin-left: 0;
        line-height: 120%;
    }

    .box-login {
        max-width: 400px;
        transform: skewX(-4deg);
        position: relative;
        margin-left: 0;

        >div {
            padding: 0 5px;

            >.MuiButton-root {
                margin-top: 10px;
                color: $azulEscuro;
            }
        }
    }
}

section.sobre-mercer {
    position: relative;
    z-index: 8;
    padding-top: 40px;
    padding-bottom: 50px;
    background: #fff;

    h2 {
        position: relative;
        display: inline-block;
        margin-left: 0;
        margin-bottom: 30px;

        i {
            position: absolute;
            display: inline-block;
            width: 100%;
            height: 100%;

            &::before {
                content: '';
                background: $azulEscuro;
                width: 100%;
                height: 100%;
                position: absolute;
                top: 0;
                left: 0;
                transform: skewX(-25deg);
            }

            &::after {
                content: '';
                background: $rosa;
                width: 100%;
                height: 100%;
                position: absolute;
                top: 10px;
                left: 5px;
                transform: skewX(-25deg);
            }
        }

        span {
            display: inline-block;
            position: relative;
            color: #fff;
            font-size: 24px;
            padding: 10px;
            line-height: 120%;
            margin-left: 20px;
            margin-top: 20px;
            margin-right: 25px;
        }
    }

    p {
        font-size: 18px;
        margin-left: 0;
        line-height: 120%;
    }

    img {
        width: 100%;
        max-width: 200px;
        margin-left: 0;
        margin-top: 30px;
    }
}

.modal-login {
    background: rgba($color: #000000, $alpha: 0.9);
    position: fixed;
    left: 0;
    top: 0;
    width: 100vw;
    height: 100vh;
    z-index: 9999;
    opacity: 0;
    visibility: hidden;
    @include easeOut();

    &.ativo-true {
        opacity: 1;
        visibility: visible;

        .box-login {
            top: 0;
        }
    }

    .box-login {
        max-width: 400px;
        transform: skewX(-4deg);
        position: relative;
        top: 100px;
        @include exporOut();

        >div {
            padding: 0 5px;

            >.MuiButton-root {
                margin-top: 10px;
                color: #fff;
            }
        }

        .fechar {
            position: absolute;
            top: -15px;
            right: -15px;
            background: $rosa;
            min-width: 0;
            border-radius: 100%;
            width: 40px;
            height: 40px;
            padding: 0;
            margin: 0;
            min-height: 0;
            color: #fff;
            transform: skewX(4deg);

            &:hover {
                background: $azul;
                color: #fff;
            }
        }
    }
}

section.gestao-foot {
    //background: $azulEscuro;
    position: relative;
    z-index: 8;

    .box-foot {
        background: $azulClaro;
        border-radius: 40px 40px 0 0;
        padding: 30px;
        text-align: center;
        max-width: 535px;
        margin-top: 30px;
        margin-left: 0;
        overflow: hidden;
        margin: 0 auto;

        h4 {
            font-size: 18px;
            font-weight: 700;
            margin-bottom: 30px;
        }

        img {
            width: 100%;
        }
    }
}

section.login {
    min-height: 100vh;
    background: $azulClaro;
    padding-top: 25px;
    padding-bottom: 0;

    &::before {
        content: '';
        width: 100%;
        height: 100%;
        position: absolute;
        left: 0;
        top: 0;
        background: url(../../assets/bg-login.png) no-repeat top left / auto 100%;
        opacity: 0.6;
    }

    .container {
        position: relative;
    }

    img.logo {
        max-width: 425px;
        width: 100%;
        height: auto;
        margin-bottom: 25px;
        margin-left: 185px;
    }

    .box-data {
        color: $azulEscuro;
        margin-top: -30px;
        margin-bottom: 40px;

        h2 {
            font-size: 110px;
            line-height: 100%;
            margin-right: 50px;
            margin-left: 175px;
            letter-spacing: -10px;
        }

        .box-count {
            position: relative;
            top: -5px;

            h4 {
                font-size: 20px;
                text-transform: uppercase;
                padding-left: 5px;
            }

            span {
                font-size: 50px;
                font-weight: 100;
                line-height: 100%;
            }
        }
    }

    .box-login {
        max-width: 320px;
        transform: skewX(-4deg);
        position: relative;
        left: calc(50% - 0);
        margin-top: -20px;

        >div {
            padding: 0 5px;

            >.MuiButton-root {
                margin-top: 10px;
                color: $azulEscuro;
            }
        }
    }
}

@media (max-width: 992px) {
    section.login .box-data h2 {
        margin-left: 160px;
    }
}

@media (max-width: 768px) {
    section.login {
        padding-bottom: 30px;
    }

    section.login img.logo {
        margin-left: 0;
    }

    section.login .box-data h2 {
        margin-left: 0;
        font-size: 67px;
        letter-spacing: -8px;
        margin-right: 25px;
    }

    section.login .box-login {
        left: 0;
    }

    section.sobre-o-evento .container .cols .col {
        width: 100%;
    }

    section.cadastro-presencial .cols .esq,
    section.cadastro-presencial .cols .dir {
        width: 100%;
    }

    section.gestao-saude img.logo {
        max-width: 160px;
    }

    section.evento-comeca .flex .esq h3 {
        font-size: 18px;
        margin-left: 0;
        margin-right: 0;
    }

    section.evento-comeca .linha-azul p {
        font-size: 15px;
        line-height: 17px;
    }

    section.gestao-saude img.kv {
        width: calc(100%);
        max-width: 1137px;
        left: -35px;
        position: relative;
        top: 10px;
    }
}