@font-face {
    font-family: 'BMW Motorrad';
    src: url('BMWMotorrad-BoldItalic.woff2') format('woff2'),
        url('BMWMotorrad-BoldItalic.woff') format('woff');
    font-weight: bold;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'BMW Motorrad';
    src: url('BMWMotorrad-Bold.woff2') format('woff2'),
        url('BMWMotorrad-Bold.woff') format('woff');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'BMW Motorrad';
    src: url('BMWMotorrad-Heavy.woff2') format('woff2'),
        url('BMWMotorrad-Heavy.woff') format('woff');
    font-weight: 900;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'BMW Motorrad';
    src: url('BMWMotorrad-HeavyItalic.woff2') format('woff2'),
        url('BMWMotorrad-HeavyItalic.woff') format('woff');
    font-weight: 900;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'BMW Motorrad';
    src: url('BMWMotorrad-Regular.woff2') format('woff2'),
        url('BMWMotorrad-Regular.woff') format('woff');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'BMW Motorrad Outline';
    src: url('BMWMotorradOutline-Regular.woff2') format('woff2'),
        url('BMWMotorradOutline-Regular.woff') format('woff');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'BMW Motorrad';
    src: url('BMWMotorrad-Italic.woff2') format('woff2'),
        url('BMWMotorrad-Italic.woff') format('woff');
    font-weight: normal;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'BMW Motorrad';
    src: url('BMWMotorrad-Light.woff2') format('woff2'),
        url('BMWMotorrad-Light.woff') format('woff');
    font-weight: 300;
    font-style: normal;
    font-display: swap;
}

