@import "../../variables";

.page.palestrantes {
    background: $azulClaro;
    min-height: 100vh;
    color: $azulEscuro;
    padding-bottom: 200px;

    h1 {
        font-size: 48px;
        font-weight: 800;
        text-transform: uppercase;
        margin-top: 30px;
        margin-bottom: 0;
    }

    .itens {
        .item {
            text-align: center;
            position: relative;
            margin: 3.5%;
            width: 16.5%;

            .box-img {
                transform: skewX(-12deg);
                display: inline-block;
                overflow: hidden;

                img {
                    width: 100%;
                    height: auto;
                    transform: skewX(12deg) scale(1.3);
                }
            }

            &::after {
                content: '';
                width: 100%;
                height: 100%;
                transform: skewX(-12deg);
                background: $azulEscuro;
                position: absolute;
                top: 40%;
                left: 10%;
            }

            .txt {
                position: relative;
                z-index: 1;

                img {
                    display: inline-block;
                }

                h3 {
                    display: flex;
                    flex-wrap: wrap;
                    font-size: 14px;
                    color: #fff;
                    position: relative;
                    left: 2.5%;
                    top: 25px;
                    font-weight: 800;
                    line-height: 20px;
                    margin-bottom: -40px;
                    margin-top: -20px;
                    min-height: 60px;
                    justify-content: center;
                    align-items: center;
                    font-weight: normal;

                    >span {

                        strong {
                            display: block;
                            width: 100%;
                            font-size: 17px;
                        }
                    }
                }

                .foot {
                    position: absolute;
                    top: calc(100% + 50% - 10px);
                    left: -10px;
                    width: 100%;

                    .palestras {
                        .MuiButton-root {
                            background: none;
                            box-shadow: none;
                            text-transform: uppercase;
                            color: $azulEscuro;
                            padding: 0;
                            font-size: 15px;
                            font-weight: 800;

                            svg {
                                height: 30px;
                                width: 30px;
                                margin-left: -5px;
                            }
                        }
                    }

                    .redes-sociais {
                        .MuiButton-root {
                            padding: 0;
                            width: auto;
                            height: auto;
                            min-width: auto;
                            min-height: auto;
                            margin-left: 5px;
                            color: #000;
                            background: $rosa;
                            border-radius: 100%;

                            &:hover {
                                background: $azul;
                                color: #fff;
                            }

                            svg {
                                width: 18px;
                                height: 18px;
                                padding: 2px;
                                color: #fff;
                            }
                        }
                    }
                }
            }
        }
    }
}

.menu-palestras {
    ul {
        li {
            pointer-events: none;
        }
    }
}

.MuiPaper-root {
    ul {
        li {
            font-size: 14px;
            margin: 0;
            padding: 0 10px;
        }
    }
}

@media (max-width: 992px) {
    .page.palestrantes .itens .item {
        margin: 8.5%;
        width: 28.5%;
        margin-bottom: 100px;
    }
}

@media (max-width: 768px) {
    .page.palestrantes .itens .item {
        margin: 30px;
        width: 200px;
        margin-bottom: 120px;
    }

    .page.palestrantes h1 {
        font-size: 30px;
        text-align: center;
    }
}