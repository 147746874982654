@import "../../variables";

section.pesquisa-satisfacao {
    background: $azulClaro;
    min-height: 100vh;
    color: $azulEscuro;
    padding-bottom: 110px;

    .container {
        max-width: 900px;
    }

    h1 {
        font-size: 22px;
        font-weight: 800;
        text-transform: uppercase;
        margin-bottom: 0;
    }

    h2 {
        font-family: 'BMW Motorrad Outline';
        font-weight: normal;
        font-size: 36px;
        text-transform: uppercase;
        margin-top: 5px;
        margin-bottom: 20px;
    }

    h3 {
        font-size: 20px;
        margin-bottom: 20px;
    }

    .campo {
        padding: 5px 15px;
        position: relative;
        margin-bottom: 10px;

        &::before {
            content: '';
            background: rgba($color: $azulEscuro, $alpha: 0.1);
            width: 100%;
            height: 100%;
            position: absolute;
            left: 0;
            top: 0;
            transform: skewX(-10deg);
        }

        h4 {
            font-size: 18px;
            margin-bottom: 7px;
            position: relative;
        }

        .MuiRating-root {
            position: relative;

            .MuiSvgIcon-root {
                color: #faaf00;
                font-size: 30px;
            }
        }
    }

    .MuiTextField-root {
        width: 100%;
        margin-top: 10px;

        .MuiInputLabel-root {
            font-size: 19px;
            line-height: 22px;
            transform: translate(14px, 18px) scale(1);
            color: $azulEscuro;
            text-transform: uppercase;

            &.MuiFormLabel-filled,
            &.Mui-focused {
                background: $rosa;
                color: #fff;
                padding-right: 7px;
                transform: translate(14px, -5px) scale(0.5);
            }
        }

        .MuiInputBase-input {
            font-size: 14px;
            line-height: 16px;
            padding: 5px 15px;
            color: $azulEscuro;
            max-height: calc(100vh - 500px);
        }

        .MuiOutlinedInput-notchedOutline {
            border: solid 3px $rosa;
        }

        .MuiOutlinedInput-root {
            &:hover {
                .MuiOutlinedInput-notchedOutline {
                    border: solid 3px rgba($color: $rosa, $alpha: 0.5);
                }
            }
        }
    }

    .bt.MuiButton-root {
        margin-top: 15px;

        &:hover {
            background: $azulEscuro;
            color: #fff;
        }
    }
}

@media (max-width: 768px) {
    section.pesquisa-satisfacao {
        padding-bottom: 160px;
    }

    section.pesquisa-satisfacao .campo {
        flex-wrap: wrap;
        justify-content: center;
        text-align: center;
    }

    section.pesquisa-satisfacao h1 {
        font-size: 15px;
    }

    section.pesquisa-satisfacao h2 {
        font-size: 25px;
    }

    section.pesquisa-satisfacao h3 {
        font-size: 15px;
    }

    section.pesquisa-satisfacao .campo h4 {
        font-size: 14px;
        width: 100%;
    }

    section.pesquisa-satisfacao .MuiTextField-root .MuiInputLabel-root {
        font-size: 12px;
    }
}