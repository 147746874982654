@import "../../variables";

div.comentarios {
    background: #fff;
    border: solid 1px #ccc;
    padding: 10px;
    width: 100%;
    height: 100%;
    border-radius: 30px;
    position: relative;
    overflow: hidden;

    h2 {
        text-align: center;
        font-size: 18px;
        border-bottom: solid 3px;
        text-transform: uppercase;
        padding: 10px 0;
        margin-bottom: 10px;
    }

    .box {
        overflow-y: auto;
        overflow-x: hidden;
        width: 100%;
        height: calc(100% - 130px);
        padding-right: 10px;
        margin-bottom: 15px;
        display: flex;
        flex-flow: column-reverse;

        .item {
            background: #333;
            padding: 10px;
            color: #fff;
            margin-bottom: 10px;
            border-radius: 5px;
            width: 100%;

            h3 {
                cursor: pointer;
                position: relative;
                margin-bottom: 7px;

                i {
                    width: 25px;
                    height: 25px;
                    display: inline-block;
                    background-size: cover;
                    border-radius: 100%;
                    margin-right: 10px;
                }
            }
        }
    }

    .form {
        width: 100%;

        &.ativo {
            .MuiButton-root {
                opacity: 1;
                pointer-events: unset;
            }
        }

        .MuiTextField-root {
            width: calc(100% - 110px);

            .MuiInputLabel-root {
                font-size: 14px;
                line-height: 110%;
                transform: translate(14px, 12px) scale(1);

                &.MuiFormLabel-filled,
                &.Mui-focused {
                    background: #fff;
                    padding-right: 7px;
                    transform: translate(14px, -6px) scale(0.75);
                }
            }

            .MuiOutlinedInput-root {
                border-radius: 15px;

                .MuiInputBase-input {
                    font-size: 14px;
                    line-height: 16px;
                    padding: 10px;
                }
            }
        }

        .MuiButton-root {
            height: 100%;
            opacity: 0.5;
            pointer-events: none;
        }
    }

    .loading-comments {
        position: absolute;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        background: rgba($color: #000000, $alpha: 0.7);
        opacity: 0;
        visibility: hidden;
        z-index: 1;
        @include easeOut();

        &.ativo {
            opacity: 1;
            visibility: visible;
        }
    }
}