@import "../../variables";

.downloads {
    background: $azulClaro;
    min-height: 100vh;
    color: $azulEscuro;

    .container-fluid {
        padding-top: 30px;
    }

    .esq {
        width: 35vw;
        border-right: solid 1px $azulEscuro;
        margin-right: 30px;
        padding-right: 30px;

        h1 {
            font-size: 48px;
            text-transform: uppercase;
            margin-bottom: 30px;
        }

        .itens {
            max-height: 45vh;
            overflow-y: auto;
            padding-right: 15px;

            .MuiButton-root {
                width: 100%;
                color: $azulEscuro;
                justify-content: space-between;
                font-size: 18px;
                border: solid 4px;
                margin-bottom: 10px;
                padding: 5px 10px;
                min-height: auto;
                height: auto;
                @include easeOut();

                &:hover {
                    background: #fff;
                    color: #000;
                    border-color: #fff;
                }

                svg {
                    font-size: 40px;
                    line-height: 0;
                    min-height: auto;
                    margin: -5px;
                }
            }
        }
    }

    .dir {
        width: 35vw;

        h2 {
            font-size: 31px;
            text-transform: uppercase;
            margin-bottom: 30px;
        }

        .itens {
            max-height: 45vh;
            overflow-y: auto;
            padding-right: 15px;

            .item {
                width: 100%;
                margin-bottom: 10px;
                border-bottom: solid 1px;
                padding-bottom: 10px;

                &:first-child {
                    border-top: solid 1px;
                    padding-top: 10px;
                }

                h4 {
                    color: $azulEscuro;
                    margin-right: 15px;
                    font-size: 18px;
                    width: calc(100% - 150px);
                }

                .MuiButton-root {
                    color: #fff;
                    justify-content: space-between;
                    font-size: 14px;
                    padding: 5px 20px;
                    min-height: auto;
                    height: auto;
                    min-width: 120px;
                    background: $rosa;
                    @include easeOut();

                    &:hover {
                        background: $azul;
                        color: #fff;
                    }
                }
            }
        }
    }
}

@media (max-width: 1200px) {

    .downloads .esq,
    .downloads .dir {
        width: 40vw;
    }
}

@media (max-width: 992px) {
    .downloads .esq h1 {
        font-size: 36px;
    }
}

@media (max-width: 768px) {

    .downloads .esq,
    .downloads .dir {
        width: 100%;
    }

    .downloads .esq {
        border-right: none;
        margin-right: 0;
        padding-right: 0;
        border-bottom: solid 1px #fff;
        margin-bottom: 30px;
        padding-bottom: 30px;
    }

    .downloads .esq .itens .MuiButton-root svg {
        transform: rotate(90deg);
    }

    .downloads .dir .itens .item h4 {
        margin-right: 0;
        width: 100%;
        margin-bottom: 10px;
    }
}