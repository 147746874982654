@import "../../variables";

.envie-sua-pergunta-component {
    color: $azulEscuro;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    background: $azulClaro;
    width: 90%;
    max-width: 600px;
    padding: 20px;
    border: solid 3px $azulEscuro;
    border-radius: 5px;

    h1 {
        font-size: 48px;
        font-weight: 800;
        text-transform: uppercase;
        margin-top: 30px;
        margin-bottom: 20px;
    }

    .MuiTextField-root {
        width: 100%;
        margin-bottom: 10px;

        .MuiInputLabel-root {
            font-size: 19px;
            line-height: 22px;
            transform: translate(14px, 18px) scale(1);
            color: $azulEscuro;
            text-transform: uppercase;

            &.MuiFormLabel-filled,
            &.Mui-focused {
                background: $rosa;
                color: #fff;
                padding-right: 7px;
                transform: translate(14px, -5px) scale(0.5);
            }
        }

        .MuiInputBase-input {
            font-size: 14px;
            line-height: 16px;
            padding: 5px 15px;
            color: $azulEscuro;
            max-height: calc(100vh - 500px);
        }

        .MuiOutlinedInput-notchedOutline {
            border: solid 3px $rosa;
        }

        .MuiOutlinedInput-root {
            &:hover {
                .MuiOutlinedInput-notchedOutline {
                    border: solid 3px rgba($color: $rosa, $alpha: 0.5);
                }
            }
        }
    }

    .bt.MuiButton-root {
        margin-top: 15px;

        &:hover {
            background: $azulEscuro;
            color: #fff;
        }
    }

    .fechar {
        position: absolute;
        top: -15px;
        right: -15px;
        background: $rosa;
        min-width: 0;
        border-radius: 100%;
        width: 40px;
        height: 40px;
        padding: 0;
        margin: 0;
        min-height: 0;
        color: #fff;

        &:hover {
            background: $azul;
            color: #fff;
        }
    }
}

@media (max-width: 768px) {
    .envie-sua-pergunta-component h1 {
        font-size: 21px;
        margin-top: 10px;
    }
}