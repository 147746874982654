@import './variables.scss';

html {
    overflow-x: hidden;
    width: 100vw;
}
body {
    color: $azulEscuro;
    font-family: 'Open Sans', sans-serif;
    margin: 0;
    font-size: 12px;
    line-height: 105%;
    position: relative;
    overflow-x: hidden;
    width: 100vw;
}

* {
    outline: none;
    box-sizing: border-box;
}

/* width */
::-webkit-scrollbar {
    width: 5px;
    height: 5px;
}

/* Track */
::-webkit-scrollbar-track {
    border-radius: 10px;
    background: none;
}

/* Handle */
::-webkit-scrollbar-thumb {
    background: $azulEscuro;
    border-radius: 10px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
    background: $rosa;
}

a,
a:hover,
a:focus {
    text-decoration: none;
    outline: none;
}

ul,
ol {
    list-style: none;
    padding: 0;
    margin: 0;
}

h1,
h2,
h3,
h4,
h5,
h6 {
    margin: 0;
    padding: 0;
    line-height: 100%;
}

.flex {
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
}

.flex-wr {
    flex-wrap: wrap;
}

.flex-ch {
    justify-content: center;
}

.flex-ri {
    justify-content: flex-end;
}

.flex-bv {
    align-items: flex-end;
}

.flex-le {
    justify-content: flex-start;
}

.flex-sp {
    justify-content: space-between;
}

.flex-sa {
    justify-content: last-baseline;
    padding-top: 50px;
}

.flex-cv {
    align-items: center;
}

.page {
    padding-bottom: 130px;
    padding-top: 220px;

    h1 {
        font-size: 26px;
        margin-bottom: 15px;
    }
}

.menu-palestras {
    .MuiList-root {
        max-width: 300px;
        padding: 10px !important;
        >div{
            margin-bottom: 5px;
            &:last-child {
                margin-bottom: 0;
            }
        }
    }
}

.MuiSnackbarContent-root {

    .MuiSnackbarContent-message {
        font-size: 12px;
    }

    .MuiButtonBase-root {
        font-size: 12px;
        color: #fff;
    }
}

body {
    .MuiButton-root {
        font-size: 12px;
        color: $azul;
    }

    .bt.MuiButton-root {
        background: $rosa;
        color: #fff;
        font-size: 16px;
        padding: 5px 20px;
        border-radius: 15px;

        &.branco {
            background: #fff;
            color: #000;
            border-radius: 15px;

            &:hover {
                background: $azulEscuro;
                color: #fff;
            }
        }

        &:hover {
            background: $azulClaro;
            color: $azulEscuro;
        }
    }

    .MuiPaper-root {
        ul {
            li {
                .MuiButton-root {
                    font-size: 18px;
                    padding: 0 10px;
                    color: #000;
                }
            }
        }
    }

    .MuiTooltip-popper * {
        font-size: 12px;
    }
}

@media (max-width: 768px) {
    .page {
        padding-top: 100px;
        padding-bottom: 165px;
    }
}