@import "../../variables";

section.not-found {
    background: #000 url(../../assets/area-usuario/bg-area-usuario.jpg) no-repeat top left / cover;
    min-height: 100vh;
    color: #fff;
    text-align: center;

    h1 {
        width: 100%;
        font-size: 30px;
        text-transform: uppercase;
    }

    h2 {
        font-size: 20px;
    }
}