@import "../../variables";

.esqueci-minha-senha-component {
    width: 100%;
    background: $azulEscuro;
    padding: 15px;

    h3 {
        font-size: 19px;
        text-transform: uppercase;
        margin-bottom: 10px;
        transform: skewX(4deg);
        color: #fff;
    }

    .MuiTextField-root {
        width: 100%;
        margin-bottom: 10px;

        .MuiInputLabel-root {
            font-size: 19px;
            line-height: 22px;
            transform: translate(14px, 5px) scale(1);
            color: #fff;
            text-transform: uppercase;

            &.MuiFormLabel-filled,
            &.Mui-focused {
                background: $rosa;
                padding-right: 7px;
                transform: translate(14px, -5px) scale(0.5);
            }
        }

        .MuiInputBase-input {
            font-size: 14px;
            line-height: 16px;
            padding: 5px 15px;
            color: #fff;
        }

        .MuiOutlinedInput-notchedOutline,
        .Mui-focused .MuiOutlinedInput-notchedOutline {
            border: solid 3px $rosa;
        }
    }

    .MuiButton-root {
        width: 100%;
        padding: 0 10px;
    }
}