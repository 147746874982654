@import "../../variables";

.transmissao-live {
    background: $azulClaro;
    min-height: 100vh;

    .box-video-e-count {
        border-radius: 30px;
        overflow: hidden;
        position: relative;

        .box-video {
            width: 100%;

            iframe {
                width: 100%;
                height: 36vw;
                border: none;
            }
        }

        .box-data-count {
            position: absolute;
            left: 0;
            top: 0;
            width: 100%;
            height: 100%;
            text-transform: uppercase;
            color: #fff;
            opacity: 0;
            visibility: hidden;
            @include easeOut();

            &::before {
                content: '';
                background: rgba($color: #000000, $alpha: 0.7);
                position: absolute;
                left: 0;
                top: 0;
                width: 100%;
                height: 100%;
                z-index: 1;
            }

            &.ativo {
                opacity: 1;
                visibility: visible;
            }

            .imagem-iframe {
                position: absolute;
                width: 100%;
                height: 100%;
                background-size: cover;
                background-position: center center;
            }

            .box-count {
                position: relative;
                z-index: 2;

                h2 {
                    font-size: 21px;
                    display: block;
                    width: 100%;
                    margin-bottom: 50px;
                }

                span {
                    font-size: 7vw;
                    font-family: 'BMW Motorrad Outline';
                }
            }
        }
    }

    .box-comentarios {
        height: calc(100vh - 198px);
    }

    .bts {
        padding: 20px;

        .MuiButton-root {
            margin-right: 20px;
            &:hover {
                background: $azulEscuro;
                color: #fff;
            }
        }
    }

    .area-arte-full {
        img {
            max-width: 100%;

            &.mobile {
                display: none;
            }
        }
    }

    .owl-carousel {
        margin-top: 30px;

        &.mobile {
            display: none;
        }

        .item {
            display: flex;
            justify-content: center;
            align-items: center;

            img {
                width: 100%;
            }
        }
    }
}

@media (max-width: 992px) {
    .transmissao-live .area-arte-full img {
        margin-top: 25px;
    }

    .transmissao-live .box-video-e-count .box-video iframe {
        height: 54vw;
    }

    .transmissao-live .box-video-e-count .box-data-count h2 {
        font-size: 16px;
        margin-bottom: 6vw;
    }

    .transmissao-live .box-video-e-count .box-data-count span {
        font-size: 10vw;
    }
}

@media (max-width: 768px) {
    .transmissao-live {
        .area-arte-full {
            img {
                &.mobile {
                    display: block;
                    margin: 15px 0;
                }

                &.desktop {
                    display: none;
                }
            }
        }

        .box-video-e-count .box-video iframe {
            width: 100%;
            height: calc(53.2vw);
            border: none;
        }

        .bts {
            padding: 0;
            margin-bottom: 15px;

            .MuiButton-root {
                margin: 5px 0;
                margin-right: 15px;
            }
        }
    }

    .transmissao-live .owl-carousel {
        &.desktop {
            display: none;
        }

        &.mobile {
            display: block;
        }
    }
}