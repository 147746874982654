@import "../../variables";

.cartao-virtual-component {
    background: rgba($color: #000000, $alpha: 0.7);
    position: fixed;
    width: 100vw;
    height: 100vh;
    top: 0;
    left: 0;
    z-index: 9;
    opacity: 0;
    visibility: hidden;
    @include easeOut();

    &.ativo {
        opacity: 1;
        visibility: visible;

        .box {
            top: 0;
        }
    }

    .box {
        background: $azulClaro;
        padding: 20px 40px;
        transform: skewX(-7deg);
        width: 100%;
        max-width: 50vw;
        position: relative;
        top: 100px;
        @include easeOut();

        .infos {
            transform: skewX(7deg);

            h1 {
                font-size: 30px;
                margin-bottom: 20px;
                text-transform: uppercase;
            }

            h2 {
                font-size: 18px;
                margin-bottom: 10px;
                padding-bottom: 10px;
                border-bottom: solid 1px;
                display: flex;
                align-items: center;

                a {
                    color: #000;
                    text-decoration: underline;
                }

                svg {
                    width: 30px;
                    height: 30px;
                    margin-right: 10px;
                }
            }

            i {
                display: block;
                width: 155px;
                height: 155px;
                background-size: cover;
                background-repeat: no-repeat;
                background-position: center center;
                border-radius: 100%;
                margin-bottom: 12px;
                position: absolute;
                right: -25px;
                top: 15px;
                border: solid 7px #fff;
            }
        }

        .fechar {
            position: absolute;
            background: $azulClaro;
            color: #000;
            bottom: 100%;
            right: 0;
            border-radius: 0;

            &:hover {
                background: $azul;
                color: #fff;
            }
        }
    }
}

@media (max-width: 992px) {

    .cartao-virtual-component .box {
        max-width: 80%;
    }
}

@media (max-width: 768px) {

    .cartao-virtual-component .box {
        max-width: 90%;
        transform: skewX(0deg);
        padding: 15px;
    }

    .cartao-virtual-component .box .infos h1 {
        font-size: 30px;
        margin-bottom: 20px;
        margin-top: 30px;
    }

    .cartao-virtual-component .box .infos {
        transform: skewX(0deg);
    }

    .cartao-virtual-component .box .infos i {
        width: 100px;
        height: 100px;
        position: absolute;
        right: auto;
        left: -20px;
        top: -115px;
    }

    .cartao-virtual-component .box .infos h2 {
        font-size: 14px;
    }
}