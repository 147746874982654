@import "../../variables";

.agenda {
    background: $azulClaro;
    min-height: 100vh;
    color: #fff;

    .container-fluid {
        width: 100%;
        >.flex {
            width: 100%;

            .esq {
                padding-right: 30px;

                h1 {
                    font-size: 78px;
                    text-transform: uppercase;
                    font-weight: 800;
                    color: $azulEscuro;
                }
            }

            .dir {
                width: 50%;
                margin-right: -15px;

                .itens {
                    .item {
                        position: relative;
                        margin-bottom: 3px;
                        overflow: hidden;
                        padding: 4px;
                        padding-right: 15px;

                        &::before {
                            content: '';
                            width: 150%;
                            height: 100%;
                            top: 0;
                            left: 5px;
                            transform: skewX(18deg);
                            background: $rosa;
                            position: absolute;
                        }

                        &:nth-child(even) {
                            &::before {
                                transform: skewX(-18deg);
                            }

                            .esq-item {
                                .mask {
                                    i {
                                        transform: skewX(-17deg);
                                    }
                                }
                            }
                        }

                        .esq-item {
                            position: relative;

                            .mask {
                                overflow: hidden;
                                margin-right: 15px;
                                position: relative;
                                padding: 4px 0;
                                padding-left: 25px;
                                padding-right: 20px;

                                i {
                                    background: $azulEscuro;
                                    position: absolute;
                                    width: 150%;
                                    height: 100%;
                                    top: 0;
                                    left: 7px;
                                    transform: skewX(17deg);
                                }

                                h4 {
                                    color: #fff;
                                    font-size: 17px;
                                    position: relative;
                                }
                            }
                        }

                        .dir-item {
                            position: relative;

                            h2 {
                                font-size: 17px;
                                color: #fff;
                            }
                        }
                    }
                }
            }
        }
    }
}

@media (max-width: 768px){
    .agenda .container-fluid > .flex .dir {
        width: 100%;
        margin-right: -15px;
    }

    .agenda .container-fluid > .flex .esq h1 {
        font-size: 44px;
    }

    .agenda .container-fluid > .flex .dir .itens .item .dir-item h2 {
        font-size: 14px;
    }
}