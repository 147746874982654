@mixin exporOut {
    -webkit-transition: all 700ms cubic-bezier(0.190, 1.000, 0.220, 1.000);
    -moz-transition: all 700ms cubic-bezier(0.190, 1.000, 0.220, 1.000);
    -o-transition: all 700ms cubic-bezier(0.190, 1.000, 0.220, 1.000);
    transition: all 700ms cubic-bezier(0.190, 1.000, 0.220, 1.000);
}

@mixin easeOut {
    -webkit-transition: all 500ms cubic-bezier(0.000, 0.000, 0.380, 1.000);
    -moz-transition: all 500ms cubic-bezier(0.000, 0.000, 0.380, 1.000);
    -o-transition: all 500ms cubic-bezier(0.000, 0.000, 0.380, 1.000);
    transition: all 500ms cubic-bezier(0.000, 0.000, 0.380, 1.000);
}

$azul: #002c77;
$roxo: #272525;
$azulClaro: #c7edff;
$azulEscuro: #002c77;
$rosa: #ee3d8b;