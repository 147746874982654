@import "../../variables";

.item-reacao-component {
    position: absolute;
    bottom: 0;
    z-index: 9;

    .anima {
        animation: xAxis 5s ease-in-out;

        img {
            width: 30px;
            position: absolute;
            opacity: 0;
            animation: yAxis 5s linear;
        }

        &.anima-1 {
            animation: xAxis1 5s ease-in-out;
        }
    }

    @keyframes yAxis {
        0% {
            transform: translateY(0);
            opacity: 0;
        }

        1% {
            opacity: 1;
        }

        100% {
            transform: translateY(-500px);
            opacity: 0;
        }
    }

    @keyframes xAxis {
        50% {
            transform: translateX(100px);
        }
    }

    @keyframes xAxis1 {
        50% {
            transform: translateX(-100px);
        }
    }
}