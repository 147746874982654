@import "../../variables";

.reacoes-component {
    .anima-reacoes {
        height: 1px;
        position: relative;
    }

    .reacoes {
        width: 100%;
        padding: 10px 0;
        opacity: 0.5;
        pointer-events: none;

        &.ativo {
            opacity: 1;
            pointer-events: unset;
        }

        .MuiButton-root {
            opacity: 0.5;
            @include easeOut();

            &:hover {
                opacity: 1;
            }

            img {
                width: 50px;
            }
        }
    }
}

@media (max-width: 768px) {
    .reacoes-component .reacoes .MuiButton-root {
        min-width: auto;

        img {
            width: 30px;
        }
    }
}